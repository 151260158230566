//141840 [TenCate] 3.3. Fields – Field details page.
import { map } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages';
import { initSystemPageContent } from '../system';
import { fieldsPageQuery } from './queries';

export default (routeData, state$, { api }) => {
    const { params: { fieldNo } } = routeData;
    return api.graphApi(fieldsPageQuery('field'), { fieldNo }).pipe(
        map(({ pages, serviceItems: { list } }) => {
            const page = pages.field;
            if (!page)
                return null;

            page.component = PageComponentNames.Field;

            page.field = list[0];

            return { page };
        }),
        initSystemPageContent(),
    );
};
