export type ComplaintCollection = {
  totalCount: number;
  items: ComplaintSegment[];
};

export type Filter = {
  caseType: string[];
  caseStatus: string[];
  caseNo: string;
};

export type ComplaintDetailResponse = {
  complaint: {
    byId: ComplaintSegment;
  } | null;
};

export type ComplaintResponse = {
  complaint: {
    list: ComplaintCollection;
  } | null;
};

export type ComplaintSegment = {
  caseNo: string;
  caseType: string | null;
  status: string | null;
  description: string | null;
  contact: string | null;
  complainLineType: string | null;
  url: string | null;
  complaintLines: any[];
  attachments: any[];
};

export type ComplaintResult = {
  caseNo: string;
  caseType: string;
  status: string | null;
  description: string;
  contact: string;
  itemType: string;
  url: string | null;
  fileNames: any[];
};

export const CaseTypeList = {
  LiningIssue: 'LINING',
  Markings: 'MARKINGS',
  Infill: 'INFILL',
  Miscellaneous: 'MISC',
};

export const CaseStatusList = {
  New: 'New',
  InProgress: 'In Progress',
  AwaitingFeedback: 'Awaiting Feedback',
  Completed: 'Completed',
  OnHold: 'On Hold',
};

//Ticket 141845: 3.12. Cases – Case create / edit form
export type ServiceItem = {
  fieldId: string;
  name: string;
};

export type ServiceItemCollection = {
  totalCount: number;
  items: ServiceItem[];
};
