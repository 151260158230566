//141840 [TenCate] 3.3. Fields � Field details page.
import { MaintenanceSegment } from './types';

type Options = {
    page: {
        index: number;
        size: number;
    };
};

export const FIELD_MAINENANCE_REQUESTED = 'FIELD_MAINENANCE_REQUESTED' as const;
/*186407 - <TenCate> The fields detail page 'Cases' and 'Scheduled maintenance' records are not related to the selected 'Field' item.*/
export const requestFieldMaintenance = (options: Options) => ({
    type: FIELD_MAINENANCE_REQUESTED,
    payload: { options },
});

export const FIELD_MAINENANCE_RECEIVED = 'FIELD_MAINENANCE_RECEIVED' as const;
export const fieldMaintenanceReceived = (items: MaintenanceSegment[]) => ({
  type: FIELD_MAINENANCE_RECEIVED,
  payload: items,
});

export const FIELD_CASES_REQUESTED = 'FIELD_CASES_REQUESTED' as const;
/*186407 - <TenCate> The fields detail page 'Cases' and 'Scheduled maintenance' records are not related to the selected 'Field' item.*/
export const requestFieldCases = (options: Options) => ({
    type: FIELD_CASES_REQUESTED,
    payload: { options },
});

export const FIELD_CASES_RECEIVED = 'FIELD_CASES_RECEIVED' as const;
export const fieldCasesReceived = (items: MaintenanceSegment[]) => ({
    type: FIELD_CASES_RECEIVED,
    payload: items,
});

export type ServiceItemsAction = ReturnType<
  | typeof requestFieldMaintenance
  | typeof fieldMaintenanceReceived
  | typeof fieldCasesReceived
>;
