//141840 [TenCate] 3.3. Fields � Field details page.
import { MaintenanceSegment } from './types';
import { FIELD_MAINENANCE_RECEIVED, FIELD_CASES_RECEIVED, ServiceItemsAction } from './actions';

type State = {
  maintenanceList: MaintenanceSegment[] | null;
};

const initialState: State = {
  maintenanceList: null,
};

export default function reducer(state: State = initialState, action: ServiceItemsAction) {
  switch (action.type) {
    case FIELD_MAINENANCE_RECEIVED:
      return {
        ...state,
        maintenanceList: action.payload,
      };
    case FIELD_CASES_RECEIVED:
      return {
        ...state,
        casesList: action.payload,
      };
    default:
      return state;
  }
}