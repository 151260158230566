import { MaintenanceCollection } from './types';

type Options = {
  page: {
    index: number;
    size: number;
  };
};

export const MAINTENANCE_DOCUMENTS_REQUESTED = 'MAINTENANCE_DOCUMENTS_REQUESTED' as const;
export const requestMaintenanceDocuments = (options: Options) => ({
  type: MAINTENANCE_DOCUMENTS_REQUESTED,
  payload: { options },
});

export const MAINTENANCE_DOCUMENTS_RECEIVED = 'MAINTENANCE_DOCUMENTS_RECEIVED' as const;
export const maintenanceDocumentsReceived = (documents: MaintenanceCollection | null, page: number) => ({
  type: MAINTENANCE_DOCUMENTS_RECEIVED,
  payload: { documents, page },
});

export type MaintenanceDocumentsReceivedAction = ReturnType<typeof maintenanceDocumentsReceived>;
export type MaintenanceDocumentsAction = ReturnType<typeof requestMaintenanceDocuments> | MaintenanceDocumentsReceivedAction;
