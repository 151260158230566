import type { LoadAttachmentResponse } from './types';
import { loadAttachmentQuery, saveServiceDocument, removeServiceDocument } from './queries';
import { ATTACHMENT_LOAD, SERVICE_DOCUMENT_SUBMITTED, ATTACHMENT_DELETED, attachmentLoaded, AttachmentAction, loadAttachment } from './actions';
import { createApiCallEpic } from '../index';

import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, pluck, mergeMap } from 'rxjs/operators';
import { toasts } from 'behavior/toasts';

export const attachmentEpic = createApiCallEpic<AttachmentAction, LoadAttachmentResponse>(
    ATTACHMENT_LOAD,
    loadAttachmentQuery,
    data => attachmentLoaded(data.attachment.list.items),
);

export const createServiceDocument: Epic<AttachmentAction> = (action$, state$, dependencies) => action$.pipe(
    ofType(SERVICE_DOCUMENT_SUBMITTED),
    pluck('payload'),
    switchMap(({ input, files }) =>
        dependencies.api.graphApi(saveServiceDocument, { input }, { files, retries: 0 }).pipe(
            mergeMap(() => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const doctype = urlParams.get('doctype');
                const docref = urlParams.get('docref');
                toasts.success('', { textKey: 'ServiceDocument_UploadSuccessMessage' });
                return of(loadAttachment(doctype || '', docref || ''));
            }),
        )),
);

export const deleteServiceDocument: Epic<AttachmentAction> = (action$, state$, dependencies) => action$.pipe(
    ofType(ATTACHMENT_DELETED),
    pluck('payload'),
    mergeMap(({ input }) =>
        dependencies.api.graphApi(removeServiceDocument, { input }).pipe(
            mergeMap(() => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const doctype = urlParams.get('doctype');
                const docref = urlParams.get('docref');
                toasts.success('', { textKey: 'ServiceDocument_DeleteSuccessMessage' });
                return of(loadAttachment(doctype || '', docref || ''));
            }),
    )),
);
