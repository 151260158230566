import { ComplaintCollection } from './types';
import {
  COMPLAINT_DOCUMENTS_RECEIVED,
  ComplaintDocumentsAction,
  ComplaintDocumentsReceivedAction,
  ComplaintServiceItemReceivedAction,
  FIELD_SERVICE_ITEM_RECEIVED,
} from './actions';
import { createReducer } from 'utils/redux';

type State = {
  docs: ComplaintCollection | null;
};

export default createReducer<State, ComplaintDocumentsAction>(null as any, {
  [COMPLAINT_DOCUMENTS_RECEIVED]: complaintsDocumentsReceived,
  [FIELD_SERVICE_ITEM_RECEIVED]: serviceItemsReceived,
});

function complaintsDocumentsReceived(state: State, action: ComplaintDocumentsReceivedAction) {
  const {
    documents,
    page,
  } = action.payload;

  if (!state.docs || page === 0 || !documents) {
    return {
      ...state,
      docs: documents,
    };
  }

  const { items, totalCount } = documents;
  const newState = {
    ...state,
    docs: {
      items: state.docs?.items.concat(items),
      totalCount,
    },
  };

  return newState;
}

function serviceItemsReceived(state: State, action: ComplaintServiceItemReceivedAction) {

  const items = action.payload;
  return {
    ...state,
    serviceItems: items ?? [],
  };
}
