export { default as complaintsHandler } from './handler';
export { default as complaintDetailHandler } from './detail/handler';

//export { default as complaintDetailHandler } from './detail/handler';
export { default as complaintCreateHandler } from './create/handler';
export { default as complaintEditHandler } from './edit/handler';

//Ticket 141845: 3.12. Cases – Case create / edit form
export { createComplaint } from './actions';
export { editComplaint } from './actions';
export { requestServiceItems } from './actions';
