//141841 [TenCate] 3.4. Contracts – Contract list page.
import { ServiceContractCollection } from './types';
import { SERVICE_CONTRACT_RECEIVED, ServiceContractsReceivedAction } from './actions';
import { createReducer } from 'utils/redux';

type State = {
    list: ServiceContractCollection | null;
};

export default createReducer<State, ServiceContractsReceivedAction>(null as any, {
    [SERVICE_CONTRACT_RECEIVED]: onLineDocumentsReceived,
});

function onLineDocumentsReceived(state: State, action: ServiceContractsReceivedAction) {
    const {
        list,
        page,
    } = action.payload;

    if (!state.list || page === 0 || !list) {
        return {
            ...state,
            list,
        };
    }

    const { items, totalCount } = list;
    return {
        ...state,
        list: {
            items: state.list.items.concat(items),
            totalCount,
        },
    };
}