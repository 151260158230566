import { COMPLAINT_REQUEST_SUBMITTED, setComplaintFailed } from '../actions';
import { createComplaintMutation } from '../queries';
import { switchMap, pluck, map, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { retryWithToast } from 'behavior/errorHandling';
import { toasts } from 'behavior/toasts';
import { catchApiErrorWithToast } from 'behavior/errorHandling';
import { requestAbility } from 'behavior/user/epic';
import { routesBuilder } from 'routes';
import { unlockForm, FormName } from 'behavior/pages';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { navigateTo } from 'behavior/events';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

export default (action$, state$, { api, logger }) => action$.pipe(
  ofType(COMPLAINT_REQUEST_SUBMITTED),
  switchMap(({ payload: { input, files } }) => {
    return api.graphApi(createComplaintMutation, { input }, { files, retries: 0 }).pipe(
      pluck('complaint', 'create', 'caseNo'),
      mergeMap(caseNo => {
        if (!caseNo)
          return of(setComplaintFailed(), unsetLoadingIndicator());

        toasts.success('', { textKey: 'ComplaintCreateRequest_Created' });

        return requestAbility(AbilityTo.ViewComplaints, state$, { api }).pipe(
          map(abilityState => abilityState === AbilityState.Available),
          map(viewComplaints => navigateTo(viewComplaints
            ? routesBuilder.forComplaintDetail(caseNo)
            : routesBuilder.forComplaints())),
        );
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormName.ComplaintCreate), unsetLoadingIndicator())),
      retryWithToast(action$, logger, _ => of(unlockForm(FormName.ComplaintCreate), unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    );
  }),
);
