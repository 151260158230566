import type { MaintenanceSegment, MaintenanceDetailResponse } from '../types';
import type { Handler } from '../../types';
import type { BackTo } from 'routes/types';
import { RouteName } from 'routes';
import { initPageContent, SystemPage, SystemPageData } from '../../system';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../../componentNames';
import { maintenanceDetailPageQuery, maintenanceDetailPreviewPageQuery } from '../queries';

const handler: Handler<MaintenanceRouteData, MaintenanceDetailPage | NotFoundPage> = (routeData, state$, dependencies) => {

    const { params: { id, previewToken } } = routeData;
    const { api } = dependencies;

    if (previewToken) {
        return api.graphApi<MaintenanceDetailPageResponse>(maintenanceDetailPreviewPageQuery).pipe(
            map(({ pages: { page } }) => {
                if (!page)
                    return null;
                const initializedPage = initPageContent(page);

                const resultPage = {
                    component: PageComponentNames.MaintenanceDetail,
                    doc: {

                    },
                    ...initializedPage,
                } as MaintenanceDetailPage;

                return { page: resultPage };
            }),
        );
    }

    return api.graphApi<MaintenanceDetailPageResponse>(maintenanceDetailPageQuery, { id }).pipe(
        map(({ pages: { page }, maintenance }) => {
            if (!page)
                return null;

            const initializedPage = initPageContent(page);
            const document = maintenance && maintenance.byId;

            if (!document) {
                return {
                    page: {
                        component: PageComponentNames.NotFound,
                        ...initializedPage,
                    } as NotFoundPage,
                };
            }

            const resultPage = {
                component: PageComponentNames.MaintenanceDetail,
                doc: document,
                ...initializedPage,
            } as MaintenanceDetailPage;

            return { page: resultPage };
        }),
    );
};

export default handler;

type Params = {
    id: string;
    previewToken: string;
};

type MaintenanceRouteData = {
    routeName: | RouteName.MaintenanceList;
    params: Params;
};

type NotFoundPage = {
    component: PageComponentNames.NotFound;
};

type MaintenanceDetailPageResponse = {
    pages: {
        page: SystemPageData;
    };
} & MaintenanceDetailResponse;

type MaintenanceDetailPage = SystemPage & {
    component: PageComponentNames;
    doc: MaintenanceSegment;
    backTo?: BackTo;
};
