import { MaintenanceCollection } from './types';
import { MAINTENANCE_DOCUMENTS_RECEIVED, MaintenanceDocumentsReceivedAction } from './actions';
import { createReducer } from 'utils/redux';

type State = {
  docs: MaintenanceCollection | null;
};

export default createReducer<State, MaintenanceDocumentsReceivedAction>(null as any, {
  [MAINTENANCE_DOCUMENTS_RECEIVED]: maintenanceDocumentsReceived,
});

function maintenanceDocumentsReceived(state: State, action: MaintenanceDocumentsReceivedAction) {
  const {
    documents,
    page,
  } = action.payload;

  if (!state.docs || page === 0 || !documents) {
    return {
      ...state,
      docs: documents,
    };
  }

  const { items, totalCount } = documents;
  const newState = {
    ...state,
    docs: {
      items: state.docs?.items.concat(items),
      totalCount,
    },
  };

  return newState;
}
