export default function parseQuery(queryString: string): Record<string, string> {
  const query: Record<string, string> = {};
  const regex = /([^?=&]+)=([^&]*)/g;

  let match;
  while ((match = regex.exec(queryString)) !== null)
    query[match[1]] = match[2];

    if (queryString.includes('servicedocument')) {
        if (queryString.split('backurl=').length > 1) {
            query.backurl = queryString.split('backurl=')[1];
        }
    }       

  return query;
}
