import type { ComplaintSegment, ComplaintDetailResponse } from '../types';
import type { Handler } from '../../types';
import type { BackTo } from 'routes/types';
import { RouteName } from 'routes';
import { initPageContent, SystemPage, SystemPageData } from '../../system';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../../componentNames';
import { complaintDetailPageQuery, complaintDetailPreviewPageQuery } from '../queries';

const handler: Handler<ComplaintRouteData, ComplaintDetailPage | NotFoundPage> = (routeData, state$, dependencies) => {

    const { params: { id, previewToken } } = routeData;
    const { api } = dependencies;

    if (previewToken) {
        return api.graphApi<ComplaintDetailPageResponse>(complaintDetailPreviewPageQuery).pipe(
            map(({ pages: { page } }) => {
                if (!page)
                    return null;
                const initializedPage = initPageContent(page);

                const resultPage = {
                    component: PageComponentNames.ComplaintDetail,
                    doc: {

                    },
                    ...initializedPage,
                } as ComplaintDetailPage;

                return { page: resultPage };
            }),
        );
    }

    return api.graphApi<ComplaintDetailPageResponse>(complaintDetailPageQuery, { id }).pipe(
        map(({ pages: { page }, complaint }) => {
            if (!page)
                return null;

            const initializedPage = initPageContent(page);
            const document = complaint && complaint.byId;

            if (!document) {
                return {
                    page: {
                        component: PageComponentNames.NotFound,
                        ...initializedPage,
                    } as NotFoundPage,
                };
            }

            const resultPage = {
                component: PageComponentNames.ComplaintDetail,
                doc: document,
                ...initializedPage,
            } as ComplaintDetailPage;

            return { page: resultPage };
        }),
    );
};

export default handler;

type Params = {
    id: string;
    previewToken: string;
};

type ComplaintRouteData = {
    routeName: | RouteName.ComplaintDetail;
    params: Params;
};

type NotFoundPage = {
    component: PageComponentNames.NotFound;
};

type ComplaintDetailPageResponse = {
    pages: {
        page: SystemPageData;
    };
} & ComplaintDetailResponse;

type ComplaintDetailPage = SystemPage & {
    component: PageComponentNames;
    doc: ComplaintSegment;
    backTo?: BackTo;
};
