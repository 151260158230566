import type { AttachmentItem } from './types';

export const ATTACHMENT_LOAD = 'ATTACHMENT/LOAD' as const;
export const loadAttachment = (doctype: string, docref: string) => ({
  type: ATTACHMENT_LOAD,
  payload: { doctype, docref },
});

export const ATTACHMENT_LOADED = 'ATTACHMENT/LOADED' as const;
export const attachmentLoaded = (attachment: AttachmentItem[]) => ({
    type: ATTACHMENT_LOADED,
    payload: attachment,
});

type ServiceDocumentInput = {
    doctype: string;
    docref: string;
    fileNames: string[];
};

export const SERVICE_DOCUMENT_SUBMITTED = 'SERVICE_DOCUMENT_SUBMITTED' as const;
export const createServiceDocument = (input: ServiceDocumentInput, files?: FileList) => ({
    type: SERVICE_DOCUMENT_SUBMITTED,
    payload: { input, files },
});

export const ATTACHMENT_DELETED = 'ATTACHMENT_DELETED' as const;
export const deleteAttachment = (input: string) => ({
    type: ATTACHMENT_DELETED,
    payload: { input },
});

export type AttachmentAction = ReturnType<
    | typeof loadAttachment
    | typeof attachmentLoaded
    | typeof createServiceDocument
    | typeof deleteAttachment
>;
