import loadable from '@loadable/component';

const ComplaintDetail = loadable(() => import(/*webpackChunkName:"return-order"*/'./complaint-detail/ComplaintDetail'));
const ComplaintsMain = loadable(() => import(/*webpackChunkName:"complain-main"*/'./complaint-list/ComplaintsMain'));
const ComplaintCreate = loadable(() => import(/*webpackChunkName:"complain-create"*/'./complaint-create/ComplaintCreateForm'));
const ComplaintEdit = loadable(() => import(/*webpackChunkName:"complain-edit"*/'./complaint-edit/ComplaintEditForm'));

function selectPropsFromPage({
  docs: {
    items,
    totalCount,
  },
  size,
  filter,
}) {
  return {
    documents: items,
    totalCount,
    size,
    filter,
  };
}

/* eslint-disable react/no-multi-comp */
export function complaintsMainRenderer(page) {
  return <ComplaintsMain {...selectPropsFromPage(page)} />;
}

export function complaintDetailRenderer(page) {
  return <ComplaintDetail />;
}

export function complaintCreateRenderer() {
  return <ComplaintCreate />;
}

export function complaintEditRenderer() {
  return <ComplaintEdit />;
}