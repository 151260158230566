//141841 [TenCate] 3.4. Contracts – Contract list page.
import type { Filter, ServiceContractResponse, ServiceContractCollection } from './types';
import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { initPageContent, SystemPage, SystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestServiceContracts } from './actions';
import { PageComponentNames } from '../componentNames';
import { RouteName } from 'routes';

export const size = 20;

export default function (componentName: PageComponentNames, query: string) {
    const handler: Handler<ServiceContractsRouteData, ServiceContractPage | NotFoundPage> = ({ params, options: pageOptions }, state$, { api }) => {
        const filter = params?.filter;

        const onlyItems = pageOptions && pageOptions.onlyItems;
        const backTo = pageOptions && pageOptions.backTo;
        const options = createOptions(params, filter, onlyItems);

        if (onlyItems)
            return of({
                action$: of(requestServiceContracts(options)),
                page: {
                    ...state$.value.page as ServiceContractPage,
                    filter,
                },
            });
        return api.graphApi<PageResponse>(query, { options }).pipe(
            map(({ pages: { page }, serviceContracts }) => {
                if (!page)
                    return null;

                const initiatedPage = initPageContent(page);
                const documents = serviceContracts && serviceContracts.list;

                if (!documents) {
                    return {
                        page: {
                            component: PageComponentNames.NotFound,
                            ...initiatedPage,
                        } as NotFoundPage,
                    };
                }

                const resultPage = {
                    component: componentName,
                    list: documents,
                    size,
                    filter,
                    backTo,
                    ...initiatedPage,
                } as ServiceContractPage;

                return { page: resultPage };
            }),
        );
    };

    return handler;
}

export function createOptions(params?: Params, filter?: Filter, onlyItems?: boolean) {
    const index = (params && params.index) || 0;
    const page = onlyItems
        ? { index, size }
        : { index: 0, size: size + size * index };
    if (!filter) {
        const initialFilter: Filter = {
            contractNo: '',
            returnFieldInformation: 0,
        };
        filter = { ...initialFilter };
    }
    return { ...filter, page };
}

type Params = {
    filter?: Filter;
    index?: number;
    previewToken?: string;
};

type ServiceContractsRouteData = {
    routeName: RouteName.Contracts;
    params?: Params;
    options?: {
        onlyItems?: boolean;
        backTo?: BackTo;
    };
};

type NotFoundPage = {
    component: PageComponentNames.NotFound;
};

type PageResponse = {
    pages: {
        page: SystemPageData;
    };
} & ServiceContractResponse;

type ServiceContractPage = SystemPage & {
    component: PageComponentNames;
    list: ServiceContractCollection;
    size: number;
    filter?: Filter;
    backTo?: BackTo;
};