//141841 [TenCate] 3.4. Contracts – Contract list page.
import loadable from '@loadable/component';

const Contracts = loadable(() => import(/*webpackChunkName:"docs"*/'./Contracts'));
const Contract = loadable(() => import(/*webpackChunkName:"docs"*/'./details/ContractDetails'));

function selectPropsFromPage({
  list: {
    items,
    totalCount,
  },
  size,
  filter,
}) {
  return {
    contracts: items,
    totalCount,
    size,
    filter,
  };
}

/* eslint-disable react/no-multi-comp */
export function contractsRenderer(page) {
  return <Contracts {...selectPropsFromPage(page)} />;
}

//____ [TenCate] 3.4. Contracts – Contract list page.
/* eslint-disable react/no-multi-comp */
export function contractRenderer() {
  return <Contract />;
}
