//141841 [TenCate] 3.4. Contracts – Contract list page.
import { map } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages';
import { initSystemPageContent } from '../system';
import { serviceContractPageQuery, serviceContractPagePreviewQuery } from './queries';

export default (routeData, state$, { api }) => {
  const { params: { contractNo, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(serviceContractPagePreviewQuery).pipe(
      map(({ pages }) => {
        const page = pages.serviceContract;
        if (!page)
          return null;

        page.component = PageComponentNames.ServiceContract;

        page.contract = {};

        return { page };
      }),
      initSystemPageContent(),
    );
  }

  const options = {
    contractNo,
    returnFieldInformation: 1,
    page: {
      index: 0,
      size: 1,
    },
  };
  return api.graphApi(serviceContractPageQuery(), { options }).pipe(
    map(({ pages, serviceContracts: { list } }) => {
      const page = pages.serviceContract;
      if (!page)
        return null;

      page.component = PageComponentNames.ServiceContract;

      page.contract = list.items.length > 0 ? list.items[0] : null;

      return { page };
    }),
    initSystemPageContent(),
  );
};