import type { Filter, ComplaintCollection, ComplaintResponse } from './types';
import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { RouteName } from 'routes';
import { initPageContent, initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestComplaintDocuments } from './actions';
import { PageComponentNames } from '../componentNames';
import { complaintPageQuery, complaintPreviewPageQuery } from './queries';
import { CaseTypeList, CaseStatusList } from './types';

export const size = 20;

const handler: Handler<ComplaintRouteData, ComplaintsPage | NotFoundPage> = ({ params, options: pageOptions }, state$, { api }) => {

  if (params?.previewToken) {

    return api.graphApi<ComplaintPreviewPageResponse>(complaintPreviewPageQuery).pipe(
      map(({ pages }) => {
        const page = pages.page;
        if (!page)
          return null;

        return {
          page: {
            component: PageComponentNames.Complaints,
            docs: { totalCount: 0, items: [] },
            size,
            filter,
            ...page,
          },
        };
      }),
      initSystemPageContent(),
    );
  }

  const onlyItems = pageOptions && pageOptions.onlyItems;
  const filter = normalizeFilter(params && params.filter, onlyItems);

  const backTo = pageOptions && pageOptions.backTo;
  const options = createOptions(params, filter, onlyItems);

  if (onlyItems)
    return of({
      action$: of(requestComplaintDocuments(options)),
      page: {
        ...state$.value.page as ComplaintsPage,
        filter,
      },
    });

  return api.graphApi<ComplaintPageResponse>(complaintPageQuery, { options }).pipe(
    map(({ pages: { page }, complaint }) => {
      if (!page)
        return null;

      const initializedPage = initPageContent(page);
      const documents = complaint && complaint.list;

      if (!documents) {
        return {
          page: {
            component: PageComponentNames.NotFound,
            ...initializedPage,
          } as NotFoundPage,
        };
      }

      const resultPage = {
        component: PageComponentNames.Complaints,
        docs: documents,
        size,
        filter,
        backTo,
        ...initializedPage,
      } as ComplaintsPage;

      return { page: resultPage };
    }),
  );
};

export default handler;

export function createOptions(params?: Params, filter?: Filter, onlyItems?: boolean) {
  const index = (params && params.index) || 0;
  const page = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  return { ...filter, page };
}

export function normalizeFilter(filter?: Filter, onlyItems?: boolean) {
  if (onlyItems && filter)
    return { ...filter };

  return {
    caseType: [CaseTypeList.Infill, CaseTypeList.LiningIssue, CaseTypeList.Markings, CaseTypeList.Miscellaneous],
    caseStatus: [CaseStatusList.New, CaseStatusList.InProgress, CaseStatusList.AwaitingFeedback],
    caseNo: '',
  };
}

type Params = {
  filter?: Filter;
  index?: number;
  previewToken?: string;
};

type ComplaintRouteData = {
  routeName: | RouteName.Complaints;
  params?: Params;
  options?: {
    onlyItems?: boolean;
    backTo?: BackTo;
  };
};

type NotFoundPage = {
  component: PageComponentNames.NotFound;
};

type ComplaintPreviewPageResponse = {
  pages: {
    page: SystemPageData;
  };
};

type ComplaintPageResponse = {
  pages: {
    page: SystemPageData;
  };
} & ComplaintResponse;

type ComplaintsPage = SystemPage & {
  component: PageComponentNames;
  docs: ComplaintCollection;
  size: number;
  filter: Filter;
  backTo?: BackTo;
};
