import { PageComponentNames } from 'behavior/pages/componentNames';
import { ATTACHMENT_LOADED, AttachmentAction, SERVICE_DOCUMENT_SUBMITTED } from './actions';
import { AttachmentItem } from './types';

type State = {
    attachment: AttachmentItem[];
    component: PageComponentNames.ServiceDocuments;
};

export default function (state: State, action: AttachmentAction) {
    switch (action.type) {
        case ATTACHMENT_LOADED:
            return { ...state, attachment: action.payload };
        case SERVICE_DOCUMENT_SUBMITTED:
            return { ...state };
        default:
            return state;
    }
}
