import { ComplaintCollection, ServiceItemCollection } from './types';

type Options = {
  page: {
    index: number;
    size: number;
  };
};

export const COMPLAINT_DOCUMENTS_REQUESTED = 'COMPLAINT_DOCUMENTS_REQUESTED' as const;
export const requestComplaintDocuments = (options: Options) => ({
  type: COMPLAINT_DOCUMENTS_REQUESTED,
  payload: { options },
});

export const COMPLAINT_DOCUMENTS_RECEIVED = 'COMPLAINT_DOCUMENTS_RECEIVED' as const;
export const complaintDocumentsReceived = (documents: ComplaintCollection | null, page: number) => ({
  type: COMPLAINT_DOCUMENTS_RECEIVED,
  payload: { documents, page },
});

export const COMPLAINT_REQUEST_SUBMITTED = 'COMPLAINT_REQUEST_SUBMITTED';
export const createComplaint = (input: any, files: any) => ({
  type: COMPLAINT_REQUEST_SUBMITTED,
  payload: { input, files },
});

export const COMPLAINT_EDIT_REQUEST_SUBMITTED = 'COMPLAINT_EDIT_REQUEST_SUBMITTED';
export const editComplaint = (input: any, files: any) => ({
  type: COMPLAINT_EDIT_REQUEST_SUBMITTED,
  payload: { input, files },
});

export const COMPLAINT_REQUEST_FAILED = 'COMPLAINT_REQUEST_FAILED';
export const setComplaintFailed = () => ({
  type: COMPLAINT_REQUEST_FAILED,
});

//Ticket 141845: 3.12. Cases – Case create / edit form
export const FIELD_SERVICE_ITEM_REQUESTED = 'FIELD_SERVICE_ITEM_REQUESTED' as const;
export const requestServiceItems = () => ({
  type: FIELD_SERVICE_ITEM_REQUESTED,
});

export const FIELD_SERVICE_ITEM_RECEIVED = 'FIELD_SERVICE_ITEM_RECEIVED' as const;
export const serviceItemsReceived = (items: ServiceItemCollection[]) => ({
  type: FIELD_SERVICE_ITEM_RECEIVED,
  payload: items,
});

export type ComplaintDocumentsReceivedAction = ReturnType<typeof complaintDocumentsReceived>;
export type ComplaintDocumentsRequestAction = ReturnType<typeof requestComplaintDocuments>;
export type ComplaintCreateAction = ReturnType<typeof createComplaint>;
export type ComplaintEditAction = ReturnType<typeof editComplaint>;

export type ComplaintServiceItemReceivedAction = ReturnType<typeof serviceItemsReceived>;
export type ComplaintServiceItemRequestAction = ReturnType<typeof requestServiceItems>;

export type ComplaintDocumentsAction =
  | ComplaintDocumentsRequestAction
  | ComplaintDocumentsReceivedAction
  |ComplaintServiceItemRequestAction
  | ComplaintServiceItemReceivedAction;
