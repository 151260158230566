import { ComplaintCreateAction, COMPLAINT_REQUEST_FAILED } from '../actions';
import { createReducer } from 'utils/redux';

type State = {

};

export default createReducer<State, ComplaintCreateAction>(null as any, {
  [COMPLAINT_REQUEST_FAILED]: (state: State) => ({ ...state, isFailed: true }),
});
