import type { Epic } from 'behavior/types';
import {
  COMPLAINT_DOCUMENTS_REQUESTED,
  complaintDocumentsReceived,
  ComplaintDocumentsAction,
  FIELD_SERVICE_ITEM_REQUESTED,
  serviceItemsReceived,
} from './actions';
import { complaintPageQuery } from './queries';
import { switchMap, pluck, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { of, merge } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { ComplaintResponse } from './types';
import { serviceItemsRequestQuery } from './queries';

const complaintsEpic: Epic<ComplaintDocumentsAction> = (action$, _, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();
  const complaintDocumentGet = action$.pipe(
    ofType(COMPLAINT_DOCUMENTS_REQUESTED),
    switchMap(({ payload: { options } }) => {
      return api.graphApi<ComplaintResponse>(complaintPageQuery, { options }).pipe(
        pluck('complaint'),
        mergeMap((results: any) => of(
          complaintDocumentsReceived(results.list, options.page.index),
          unsetLoading,
        )),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        takeUntil(locationChanged$),
        startWith(setLoading),
      );
    }),
  );

  const serviceItemGet = action$.pipe(
    ofType(FIELD_SERVICE_ITEM_REQUESTED),
    switchMap(() => api.graphApi(serviceItemsRequestQuery, { fieldNo: '' }).pipe(
      pluck('serviceItems', 'list'),
      mergeMap(serviceItems => {
        return of(serviceItemsReceived(serviceItems));
      }),
    )),
  );

  return merge(complaintDocumentGet, serviceItemGet);
};

export default complaintsEpic;
