import styles from './Summary.module.scss';
import { memo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { useLayoutShifter, useExtras } from 'utils/layout';
import { CartSummaryIcon } from 'components/primitives/icons';
import { getThemeFontSizeClassName } from 'components/theme';
import { BasketLinkLayoutOption } from './constants';

const Header = ({ totalCount, showFixed, options, style, totalLineCount }) => {
  const extras = useExtras();
  const ref = useRef();
  const { topFixedElementsHeight } = useLayoutShifter();

  useEffect(() => {
    if (!showFixed)
      return;

    // Updates basket link top style property depending on layout top shift value.
    ref.current.style.top = `${topFixedElementsHeight}px`;
  }, [showFixed, topFixedElementsHeight]);
  const basketTotalOptionValue = options.basketTotalOption === 0 ? totalCount : totalLineCount;
  const basketTotalOptionText = options.basketTotalOption === 0 ? 'ShoppingBasket_QuantityPostfix' : 'ShoppingBasket_LinesPostfix';

  let iconWithText;
  switch (options.basketLinkLayout) {
    case BasketLinkLayoutOption.TextLeftOfIcon:
      iconWithText = (
        <>
          <span className={styles.summaryNumber}>{basketTotalOptionValue}</span>
          {options.showBasketLinkLabel && <span> <SimpleText textKey={basketTotalOptionText} /></span>}
          <CartSummaryIcon className={joinClasses(styles.icon, styles.textLeft)} aria-hidden />
        </>
      );
      break;
    case BasketLinkLayoutOption.TextBelowOfIcon:
      iconWithText = (
        <>
          <CartSummaryIcon className={joinClasses(styles.icon, styles.noMargin)} aria-hidden />
          <span className={styles.textBelowIcon}>
            <span className={styles.summaryNumber}>{basketTotalOptionValue}</span>
            {options.showBasketLinkLabel && <span> <SimpleText textKey={basketTotalOptionText} /></span>}
          </span>
        </>
      );
      break;
    case BasketLinkLayoutOption.ShowNumberOfProductsAsBadge:
      iconWithText = (
        <span className={styles.badgeContainer}>
          <CartSummaryIcon className={joinClasses(styles.icon, styles.noMargin)} aria-hidden />
          {!!basketTotalOptionValue && <span className={`${styles.summaryNumber} ${styles.badge}`}>{basketTotalOptionValue}</span>}
        </span>
      );
      break;
    default:
      iconWithText = (
        <>
          <CartSummaryIcon className={styles.icon} aria-hidden />
          <span className={styles.summaryNumber}>{basketTotalOptionValue}</span>
          {options.showBasketLinkLabel && <span> <SimpleText textKey={basketTotalOptionText} /></span>}
        </>
      );
      break;
  }

  const basketLink = (
    <Link
      className={
        joinClasses(
          styles.header,
          !basketTotalOptionValue && styles.isEmpty,
          !options.showBasketLinkLabel && styles.withoutLabel,
          showFixed && styles.fixed,
          getThemeFontSizeClassName(options.basketLinkThemeFontSize),
        )
      }
      to={routesBuilder.forBasket}
      ref={ref}
      style={showFixed && extras ? style : null}
    >
      {iconWithText}
    </Link>
  );

  return (
    <div style={style}>
      {showFixed && extras ? ReactDOM.createPortal(basketLink, extras) : basketLink}
      <span
        className={joinClasses(
          styles.placeholder,
          showFixed && styles.fixed,
          getThemeFontSizeClassName(options.basketLinkThemeFontSize),
        )}
        aria-hidden
      >
        <CartSummaryIcon className={styles.icon} aria-hidden />
        <span className={styles.summaryNumber}>{basketTotalOptionValue}</span>
      </span>
    </div>
  );
};

Header.propTypes = {
  totalCount: PropTypes.number,
  totalLineCount: PropTypes.number,
  showFixed: PropTypes.bool,
  options: PropTypes.shape({
    basketLinkLayout: PropTypes.number.isRequired,
    showBasketLinkLabel: PropTypes.bool.isRequired,
    basketLinkThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
};

export default memo(Header);
