import type { Filter, MaintenanceCollection, MaintenanceResponse } from './types';
import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { RouteName } from 'routes';
import { initPageContent, initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestMaintenanceDocuments } from './actions';
import { PageComponentNames } from '../componentNames';
import DateOnly from 'date-only';
import { maintenancePageQuery, maintenancePagePreviewPageQuery } from './queries';

export const size = 20;

const handler: Handler<MaintenanceRouteData, MaintenancePage | NotFoundPage> = ({ params, options: pageOptions }, state$, { api }) => {
  const filter = normalizeFilter(params && params.filter);
  if (params?.previewToken) {

    return api.graphApi<MaintenancePreviewPageResponse>(maintenancePagePreviewPageQuery).pipe(
      map(({ pages }) => {

        const page = pages.page;
        if (!page)
          return null;

        return {
          page: {
            component: PageComponentNames.MaintenanceList,
            docs: { totalCount: 0, items: [] },
            size,
            filter,
            ...page,
          },
        };
      }),
      initSystemPageContent(),
    );
  }

  const onlyItems = pageOptions && pageOptions.onlyItems;
  const backTo = pageOptions && pageOptions.backTo;
  const options = createOptions(params, filter, onlyItems);

  if (onlyItems)
    return of({
      action$: of(requestMaintenanceDocuments(options)),
      page: {
        ...state$.value.page as MaintenancePage,
        filter,
      },
    });

  return api.graphApi<MaintenancePageResponse>(maintenancePageQuery, { options }).pipe(
    map(({ pages: { page }, maintenance }) => {
      if (!page)
        return null;

      const initializedPage = initPageContent(page);
      const documents = maintenance && maintenance.list;

      if (!documents) {
        return {
          page: {
            component: PageComponentNames.NotFound,
            ...initializedPage,
          } as NotFoundPage,
        };
      }

      const resultPage = {
        component: PageComponentNames.MaintenanceList,
        docs: documents,
        size,
        filter,
        backTo,
        ...initializedPage,
      } as MaintenancePage;

      return { page: resultPage };
    }),
  );
};

export default handler;

export function createOptions(params?: Params, filter?: Filter, onlyItems?: boolean) {
  const index = (params && params.index) || 0;
  const page = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  return { ...filter, page };
}

export function normalizeFilter(filter?: Filter) {
  if (filter && (filter.startDate == null || filter.startDate ))
    return filter;

  const from = new Date();
  let fromDate = from.getDate();
  const fromMonth = from.getMonth() - 3;
  const fromMonthLastDay = getNumberOfDaysInMonth(fromMonth + 1, from.getFullYear());
  if (fromMonthLastDay < fromDate)
    fromDate = fromMonthLastDay;

  from.setMonth(fromMonth, fromDate);

  return { ...filter, startDate: DateOnly.toISOString(from), endDate: null };
}

function getNumberOfDaysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

type Params = {
  filter: Filter;
  index?: number;
  previewToken?: string;
};

type MaintenanceRouteData = {
  routeName: | RouteName.MaintenanceList;
  params?: Params;
  options?: {
    onlyItems?: boolean;
    backTo?: BackTo;
  };
};

type NotFoundPage = {
  component: PageComponentNames.NotFound;
};

type MaintenancePreviewPageResponse = {
  pages: {
    page: SystemPageData;
  };
};

type MaintenancePageResponse = {
  pages: {
    page: SystemPageData;
  };
} & MaintenanceResponse;

type MaintenancePage = SystemPage & {
  component: PageComponentNames;
  docs: MaintenanceCollection;
  size: number;
  filter: Filter;
  backTo?: BackTo;
};
