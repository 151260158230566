//140857 [TenCate] 3.2. Fields – Field list page.
import loadable from '@loadable/component';

const ServiceItems = loadable(() => import(/*webpackChunkName:"docs"*/'./ServiceItems'));
const ServiceItemDetail = loadable(() => import(/*webpackChunkName:"docs"*/'./ServiceItemDetail'));

/* eslint-disable react/no-multi-comp */
export function serviceItemsRenderer() {
  return <ServiceItems />;
}

//____ [TenCate] 3.3. Fields – Field details page.
/* eslint-disable react/no-multi-comp */
export function serviceItemDetailRenderer() {
  return <ServiceItemDetail />;
}
