import type { Handler } from '../../types';
import type { BackTo } from 'routes/types';
import { RouteName } from 'routes';
import { initPageContent, SystemPage, SystemPageData } from '../../system';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../../componentNames';
import { complaintEditPageQuery, complaintEditPreviewPageQuery } from '../queries';
import { ComplaintSegment } from '../types';

const handler: Handler<ComplaintRouteData, ComplaintEditPage | NotFoundPage> = (routeData, state$, dependencies) => {

    const { params: { id, previewToken } } = routeData;
    const { api } = dependencies;

    if (previewToken) {
        return api.graphApi<ComplaintEditPageResponse>(complaintEditPreviewPageQuery).pipe(
            map(({ pages: { page } }) => {
                if (!page)
                    return null;
                const initializedPage = initPageContent(page);

                const complaintObj : ComplaintSegment = {
                    caseNo:'',
                    caseType:'',
                    complainLineType:'',
                    contact:'',
                    description:'',
                    status:'',
                    url:'',
                    complaintLines: [{
                        itemType: 'item',
                        item: '',
                    }],
                    attachments: [],
                };

                const resultPage = {
                    component: PageComponentNames.ComplaintEdit,
                    fileUploadSettings: {
                    },
                    complaint: complaintObj,
                    ...initializedPage,
                } as ComplaintEditPage;

                return { page: resultPage };
            }),
        );
    }

    return api.graphApi<ComplaintEditPageResponse>(complaintEditPageQuery, { id }).pipe(
        map(({ pages: { page }, complaint, settings: { complaint: { attachments } } }) => {
            if (!page)
                return null;
            const initializedPage = initPageContent(page);
            const document = complaint && complaint.byId;

            const resultPage = {
                component: PageComponentNames.ComplaintEdit,
                fileUploadSettings: attachments,
                complaint: document,
                ...initializedPage,
            } as ComplaintEditPage;

            return { page: resultPage };
        }),
    );
};

export default handler;

type Params = {
    id: string;
    previewToken: string;
};

type ComplaintRouteData = {
    routeName: | RouteName.ComplaintEdit;
    params: Params;
};

type NotFoundPage = {
    component: PageComponentNames.NotFound;
};

type ComplaintEditPageResponse = {
    pages: {
        page: SystemPageData;
    };
} & ComplaintEditResponse;

type ComplaintEditPage = SystemPage & {
    component: PageComponentNames;
    fileUploadSettings: FileUploadSettings;
    complaint: ComplaintSegment;
    backTo?: BackTo;
};

type ComplaintEditResponse = {
    settings: Settings;
    complaint: {
        byId: ComplaintSegment;
    } | null;
};

type Settings = {
    complaint: {
        attachments: FileUploadSettings;
    };
};

type FileUploadSettings = {
    acceptedTypes: any;
    allowMultiple: any;
    maxNameLength: any;
    maxSize: any;
    maxFilesCount: any;
};
