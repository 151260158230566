//141840 [TenCate] 3.3. Fields � Field details page.
import type { Epic } from 'behavior/types';
import { FIELD_MAINENANCE_REQUESTED, FIELD_CASES_REQUESTED, fieldMaintenanceReceived, fieldCasesReceived, ServiceItemsAction } from './actions';
import { fieldMaintenanceQuery, fieldCasesQuery } from './queries';
import { switchMap, pluck, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { MaintenanceResponse, ComplaintResponse } from './types';

export const serviceItemsMaintenanceEpic: Epic<ServiceItemsAction> = (action$, _, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
    const setLoading = setLoadingIndicator();
    const unsetLoading = unsetLoadingIndicator();
    return action$.pipe(
        ofType(FIELD_MAINENANCE_REQUESTED),
        switchMap(({ payload: { options } }) => {
              return api.graphApi<MaintenanceResponse>(fieldMaintenanceQuery, { options }).pipe(
                pluck('maintenance', 'list', 'items'),
                mergeMap((maintenance: any) => of(
                    fieldMaintenanceReceived(maintenance),
                    unsetLoading,
                )),
                retryWithToast(action$, logger, _ => of(unsetLoading)),
                takeUntil(locationChanged$),
                startWith(setLoading),
            );
        }),
    );
};


export const serviceItemsCasesEpic: Epic<ServiceItemsAction> = (action$, _, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
    const setLoading = setLoadingIndicator();
    const unsetLoading = unsetLoadingIndicator();
    return action$.pipe(
        ofType(FIELD_CASES_REQUESTED),
        switchMap(({ payload: { options } }) => {
            return api.graphApi<ComplaintResponse>(fieldCasesQuery, { options }).pipe(
                pluck('complaint', 'list', 'items'),
                mergeMap((cases: any) => of(
                    fieldCasesReceived(cases),
                    unsetLoading,
                )),
                retryWithToast(action$, logger, _ => of(unsetLoading)),
                takeUntil(locationChanged$),
                startWith(setLoading),
            );
        }),
    );
};
