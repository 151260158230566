import loadable from '@loadable/component';

const MaintenanceListMain = loadable(() => import(/*webpackChunkName:"return-order"*/'./Maintenance-list/MaintenanceListMain'));
const MaintenanceDetail = loadable(() => import(/*webpackChunkName:"return-order"*/'./Maintenance-detail/MaintenanceDetail'));

function selectPropsFromPage({
  docs: {
    items,
    totalCount,
  },
  size,
  filter,
}) {
  return {
    documents: items,
    totalCount,
    size,
    filter,
  };
}

/* eslint-disable react/no-multi-comp */
export function maintenanceListMainRenderer(page) {
  return <MaintenanceListMain {...selectPropsFromPage(page)} />;
}

export function maintenanceDetailRenderer(page) {
  return <MaintenanceDetail />;
}
