//141841 [TenCate] 3.4. Contracts – Contract list page.
import type { Epic } from 'behavior/types';
import { SERVICE_CONTRACT_REQUESTED, serviceContractsReceived, ServiceContractsAction } from './actions';
import { serviceContractsQuery } from './queries';
import { switchMap, pluck, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { ServiceContractResponse } from './types';

const serviceContractsEpic: Epic<ServiceContractsAction> = (action$, _, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
    const setLoading = setLoadingIndicator();
    const unsetLoading = unsetLoadingIndicator();
    return action$.pipe(
        ofType(SERVICE_CONTRACT_REQUESTED),
        switchMap(({ payload: { options } }) => {
            return api.graphApi<ServiceContractResponse>(serviceContractsQuery, { options }).pipe(
                pluck('serviceContracts'),
                mergeMap((results: any) => of(
                    serviceContractsReceived(results.list, options.page.index),
                    unsetLoading,
                )),
                retryWithToast(action$, logger, _ => of(unsetLoading)),
                takeUntil(locationChanged$),
                startWith(setLoading),
            );
        }),
    );
};

export default serviceContractsEpic;