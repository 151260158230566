//141841 [TenCate] 3.4. Contracts – Contract list page.
import { ServiceContractCollection } from './types';

type Options = {
    page: {
        index: number;
        size: number;
    };
    contractNo?: string;
};

export const SERVICE_CONTRACT_REQUESTED = 'SERVICE_CONTRACT_REQUESTED' as const;
export const requestServiceContracts = (options: Options) => ({
    type: SERVICE_CONTRACT_REQUESTED,
    payload: { options },
});

export const SERVICE_CONTRACT_RECEIVED = 'SERVICE_CONTRACT_RECEIVED' as const;
export const serviceContractsReceived = (list: ServiceContractCollection | null, page: number) => ({
    type: SERVICE_CONTRACT_RECEIVED,
    payload: { list, page },
});

export type ServiceContractsReceivedAction = ReturnType<typeof serviceContractsReceived>;
export type ServiceContractsAction = ReturnType<typeof requestServiceContracts> | ServiceContractsReceivedAction;