import type { Epic } from 'behavior/types';
import { MAINTENANCE_DOCUMENTS_REQUESTED, maintenanceDocumentsReceived, MaintenanceDocumentsAction } from './actions';
import { maintenancePageQuery } from './queries';
import { switchMap, pluck, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { MaintenanceResponse } from './types';

const maintenanceEpic: Epic<MaintenanceDocumentsAction> = (action$, _, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();
  return action$.pipe(
    ofType(MAINTENANCE_DOCUMENTS_REQUESTED),
    switchMap(({ payload: { options } }) => {
      return api.graphApi<MaintenanceResponse>(maintenancePageQuery, { options }).pipe(
        pluck('maintenance'),
        mergeMap((results: any) => of(
          maintenanceDocumentsReceived(results.list, options.page.index),
          unsetLoading,
        )),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        takeUntil(locationChanged$),
        startWith(setLoading),
      );
    }),
  );
};

export default maintenanceEpic;
